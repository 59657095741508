<template>
    <div v-if="validationErrors.length">
        <ul class="alert alert-danger">
            <li class="ml-3" v-for="(value, key) in validationErrors" v-bind:key="key"> {{ value }}</li>
        </ul>
    </div>
</template>

<script>
    export default{
        props: ['errors'],
        name: 'ValidationErrors',
        computed: {
            validationErrors(){
                let errors = Object.values(this.errors);
                errors = errors.flat();
                return errors;
            }
        }
    }
</script>