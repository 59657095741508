import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/auth'
import axios from 'axios';
// import VueSignaturePad from 'vue-signature-pad';
// import Vue from 'vue'
// import VueSignature from "vue-signature-pad";
Vue.config.productionTip = false
// window.axios = axios;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Accept'] = 'application/json';
// axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.localStorage.getItem('stpaul-token');
// 'Authorization': 'Bearer ' + token

// axios.defaults.baseURL = 'http://127.0.0.1:8941/api/';
axios.defaults.baseURL = 'https://services.thestprestaurant.com/api/';
axios.defaults.withCredentials = true;
Vue.prototype.axios = axios;
// Vue.use(VueSignaturePad);
import Vue2Filters from 'vue2-filters'
import VModal from 'vue-js-modal'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
// Vue.use(VueSignature);
// import loader from './views/components/OverlaySpinner.vue';
import ValidationErrors from './views/components/ValidationErrors.vue';
import SuccessAlert from './views/components/SuccessAlert.vue';
// Vue.component('vue-signature-pad',VueSignaturePad);
// Vue.component('LoaderView', loader);
Vue.component('validation-errors', ValidationErrors);
Vue.component('alert-success', SuccessAlert);
Vue.use(Vue2Filters)
Vue.use(VueLoading);
Vue.use(VModal, {dynamicDefault: {draggable: true, resizable: true, adaptive: true}})
export const bus = new Vue();
Vue.prototype.$loader = null;
export const debounce = require('debounce');
Vue.filter('formatDate', function (value) {
    if (value) {
        let date = value.split('-');
        let year = date[0];
        let month = date[1];
        let day = date[2];
        return month + "/" + day + '/' + year;
    }
});
// Vue.use(bus);
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
