<template>
    <div class=" p-3 text-info bg-white sidebar"
         style="">
        <a href="/"
           class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-info text-decoration-none">
            <svg class="bi me-2" width="40" height="32">
                <use xlink:href="#bootstrap"></use>
            </svg>
            <img src="images/st_paul_logo.png" class="logo" alt="">
        </a>
        <hr>

        <ul class="nav nav-pills flex-column ">
            <li class="nav-item">
                <router-link href="#" title="Home" class="nav-link text-left" :to="{'name':'index'}"
                             aria-current="page">
                    <img src="@/assets/home.png" class="side-icon" alt="">
                    <span class="text-center">Home</span>
                </router-link>
            </li>
            <!--<li>-->
            <!--<router-link :to="{'name':'dashboard'}" :class="[isActive('dashboard')?'active ':'']" href="#"-->
            <!--class="nav-link text-custom text-left">-->
            <!--<svg class="bi me-2" width="16" height="16">-->
            <!--<use xlink:href="#speedometer2"></use>-->
            <!--</svg>-->
            <!--Dashboard-->
            <!--</router-link>-->
            <!--</li>-->
            <li class="nav-item" v-if="show('sponsor')">
                <router-link :to="{'name':'sponsor'}" href="#" :class="[isActive('sponsor')?'active ':'']"
                             class="nav-link text-custom text-left">
                    <img src="@/assets/users.png" class="side-icon" alt="">
                    <span class="text-center  ">
                    Sponsors
                    </span>
                </router-link>
            </li>

            <li class="nav-item" v-if="show('menu')">
                <router-link href="#" :to="{'name':'menu'}" :class="[isActive('menu')?'active ':'']"
                             class="nav-link text-custom text-left">
                    <img src="@/assets/menu.png" class="side-icon" alt="">
                    <span class="text-center ">
                    Menu
                    </span>
                </router-link>
            </li>
            <li class="nav-item" v-if="show('delivery_slips')">

                <a :class="[isActive('delivery_slips')?'active ':'']" @click.prevent="showSignatureError()"
                   class="nav-link text-custom text-left"
                   v-if="(role==12||role==2) && $store.getters.user.detail.signature.length==0">
                    <img src="@/assets/delivery_slip.png" class="side-icon" alt="">
                    <span class="text-center ">
                    Delivery Slips
                    </span>
                    </a>

                <router-link href="#" :to="{'name':'delivery_slips'}" :class="[isActive('delivery_slips')?'active ':'']"
                             class="nav-link text-custom text-left" v-else>
                    <img src="@/assets/delivery_slip.png" class="side-icon" alt="">
                    <span class="text-center ">
                    Delivery Slips
                    </span>
                </router-link>
            </li>

            <li class="nav-item" v-if="show('users')">
                <router-link href="#" :to="{'name':'users'}" :class="[isActive('users')?'active ':'']"
                             class="nav-link text-custom text-left">
                    <img src="@/assets/users.png" class="side-icon" alt="">
                    <span class="text-center ">
                    Users
                    </span>
                </router-link>
            </li>
            <li class="nav-item" v-if="show('food_production_record')">
                <router-link href="#" :to="{'name':'food_production_record'}"
                             :class="[isActive('food_production_record')?'active ':'']"
                             class="nav-link text-custom text-left">
                    <img src="@/assets/food_production.png" class="side-icon" alt="">
                    <span class="text-center ">
                    Food Production                    </span>
                </router-link>
            </li>
            <li class="nav-item" v-if="show('orders')">
                <router-link href="#" :to="{'name':'orders'}" :class="[isActive('orders')?'active ':'']"
                             class="nav-link text-left">
                    <img src="@/assets/shopping-bag.png" class="side-icon" alt="">
                    <span class="text-center ">
                    Orders
                    </span>
                </router-link>
            </li>
            <li class="nav-item" v-if="show('meal_count')">
                <router-link href="#" :to="{'name':'meal_count'}" :class="[isActive('meal_count')?'active ':'']"
                             class="nav-link text-left">
                    <img src="@/assets/telly_counter.png" class="side-icon" alt="">
                    <span class="text-center ">
                    Meal Count
                    </span>
                </router-link>
            </li>
            <li class="nav-item" v-if="show('drivers.signature')">
                <router-link href="#" :to="{'name':'drivers.signature'}"
                             :class="[isActive('drivers.signature')?'active ':'']"
                             class="nav-link text-left">
                    <img src="@/assets/signature.png" class="side-icon" alt="">
                    <span class="text-center ">
                    Signature
                    </span>
                </router-link>
            </li>
            <li class="nav-item" v-if="show('sponsor_representative.signature')">
                <router-link href="#" :to="{'name':'sponsor_representative.signature'}"
                             :class="[isActive('sponsor_representative.signature')?'active ':'']"
                             class="nav-link text-left">
                    <img src="@/assets/signature.png" class="side-icon" alt="">
                    <span class="text-center ">
                    Signature
                    </span>
                </router-link>
            </li>
            <li class="nav-item" v-if="show('invoice')">
                <router-link href="#" :to="{'name':'invoice'}" :class="[isActive('invoice')?'active ':'']"
                             class="nav-link text-left">
                    <img src="@/assets/invoice.png" class="side-icon" alt="">
                    <span class="text-center ">
                    Invoice
                    </span>
                </router-link>
            </li>
            <li class="nav-item" v-if="show('documents')">
                <router-link href="#" :to="{'name':'documents'}" :class="[isActive('documents')?'active ':'']"
                             class="nav-link text-left">
                    <img src="@/assets/documents.png" class="side-icon" alt="">
                    <span class="text-center ">
                    Documents
                    </span>
                </router-link>
            </li>
            <li class="nav-item" v-if="show('contracts')">
                <router-link href="#" :to="{'name':'contracts'}" :class="[isActive('contracts')?'active ':'']"
                             class="nav-link text-left">
                    <img src="@/assets/price.png" class="side-icon" alt="">
                    <span class="text-center ">
                    Price Contracts
                    </span>
                </router-link>
            </li>

            <li class="nav-item" v-if="show('brand')">
                <router-link href="#" :to="{'name':'brand'}" :class="[isActive('brand')?'active ':'']"
                             class="nav-link text-left">
                    <img src="@/assets/data.png" class="side-icon" alt="">
                    <span class="text-center ">
                    Meal Identifier/Brand
                    </span>
                </router-link>
            </li>
            <li class="nav-item" v-if="show('meal_contribution')">
                <router-link href="#" :to="{'name':'meal_contribution'}"
                             :class="[isActive('meal_contribution')?'active ':'']"
                             class="nav-link text-left">
                    <img src="@/assets/data.png" class="side-icon" alt="">
                    <span class="text-center ">
                    Meal Contributor
                    </span>
                </router-link>
            </li>
            <li class="nav-item" v-if="show('items')">
                <router-link href="#" :to="{'name':'items'}"
                             :class="[isActive('items')?'active ':'']"
                             class="nav-link text-left">
                    <img src="@/assets/data.png" class="side-icon" alt="">
                    <span class="text-center ">
                    Items
                    </span>
                </router-link>
            </li>

            <li class="nav-item">
                <a href="#" @click.prevent="logout()" class="nav-link text-left ">
                    <img src="@/assets/logout.png" class="side-icon" alt="">
                    <span class="text-center ">
                    Logout
                    </span>
                </a>
            </li>
        </ul>
        <hr>

    </div>
</template>

<script>
    export default{
        data(){
            return {
                role: 0,
                school: null,
            }
        },
        methods: {

            get_routes(){

                if (this.role == 1) {
                    return [
                        'menu', 'delivery_slips', 'users',
                        'drivers', 'chefs', 'sfa', 'community_sponsors',
                        'nutritionist', 'employer', 'food_production_record', 'orders',
                        'invoice', 'documents', 'meal_count', 'contracts', 'brand',
                        'meal_contribution','items',

                    ];
                }
                if (this.role == 3) {
                    return [
                        'menu', 'delivery_slips',
                        'food_production_record', 'orders', 'invoice', 'documents'

                    ];
                }
                if (this.role == 2) {
                    return ['delivery_slips', 'drivers.signature', 'documents'];
                }
                if (this.role == 5) {
                    return ['food_production_record', 'documents'];
                }
                if (this.role == 9) {
                    return ['food_production_record', 'orders', 'meal_count', 'documents'];
                }
                if (this.role == 12) {
                    return ['delivery_slips', 'sponsor_representative.signature'];
                }
                return [];
            },

            isActive(route){
                console.log(route);
//                return false;
                return this.$route.name == route;
            },
            show(name){
                let array = this.get_routes();
//                console.log(array);
                return array.includes(name);
            },
            logout(){
                window.localStorage.removeItem('menu_filter');
                window.localStorage.removeItem('stpaul-auth');
                window.localStorage.removeItem('stpaul-token');
                window.localStorage.removeItem('stpaul-user');
                window.location.reload();
//                abeera@hgacademy.org
//                Abeer2022@
            },
            showSignatureError(){
                alert('Please save your signatures before using delivery slips');
            }
        },
        computed: {
            role_name(){
                if (this.role == 1) {
                    return 'Vendors';
                }
                if (this.role == 2) {
                    return 'Drivers';
                }
                if (this.role == 3) {
                    return 'School Food Authorities';
                }
                if (this.role == 4) {
                    return 'Community Sponsors';
                }
                if (this.role == 5) {
                    return 'Chefs';
                }
                if (this.role == 6) {
                    return 'Compliance Officers';
                }
                if (this.role == 7) {
                    return 'Nutritionists';
                }
                if (this.role == 8) {
                    return 'Employers';
                }
                return '';
            }
        },
        mounted(){

            if (!this.$store.getters.authenticated) {
                return;
            }

            this.role = this.$store.getters.user.role;

            if (this.role == 3) {
                let user = this.$store.getters.user.school_food_authority;

                this.school = user.sponsor.name;
            }


//            function getUser(){
//            console.log(this.$store.getters.token);

        }
    }
</script>

<style>
    .side-icon {
        width: 16px;
        margin-right: 5px;
        margin-bottom: 6px;
    }

    .sidebar span {
        color: #25396f !important;
    }

    .sidebar .active {

        /*min-width: 80px;*/
        /*max-width: 80px;*/
        color: white !important;
        background-color: #435ebe !important;
        /*color:white !important;*/
        /*text-align: center;*/

    }

    .sidebar .active span {

        color: white !important;

    }

    /*.sidebar .nav-item  {*/
    /*color:inherit !important;*/
    /*}*/

    /*#sidebar .text-info{*/
    /*color:#25396f !important;*/
    /*}*/
    .nav-item {
        border-bottom: 1px solid #f2f2f2;
        text-align: center;
    }

    ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
    }
</style>