<template>
    <div id="app">
        <header class="site-header" v-if="!showSideMenu">
            <div class="row align-items-center bg-light">
                <div class="col-5 col-md-3">

                </div>
                <div class="col-2 mt-2 mb-2 col-md-6 text-center site-logo-wrap">
                    <a href="index.html" class="site-logo">
                        <img src="images/st_paul_logo.png" class="logo" alt="">
                    </a>
                </div>
                <!--<div class="col-5 col-md-3 text-right menu-burger-wrap">-->
                <!--<a href="#" class="site-nav-toggle js-site-nav-toggle"><i></i></a>-->

                <!--</div>-->
            </div>

            <nav class="navbar navbar-expand-lg navbar-light bg-light navbar-fixed ">

                <div class="" id="navbarSupportedContent">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item active">
                            <router-link class="nav-link" :to="{'name':'index'}">Home</router-link>
                            <!--<a class="nav-link" href="rou">Home <span class="sr-only">(current)</span></a>-->
                        </li>


                        <li class="nav-item" v-if="!logged_in || role==0">
                            <router-link class="nav-link" :to="{'name':'login'}">Login</router-link>
                        </li>

                        <li class="nav-item" v-if="logged_in">
                            <router-link class="nav-link" :to="{'name':'dashboard'}">My Account</router-link>
                        </li>


                    </ul>
                    <ul class="navbar-nav ml-auto">

                        <li class="nav-item right" v-if="logged_in">
                            <a class="nav-link" href="#">Welcome! {{$store.getters.user.name}}</a>
                        </li>

                        <li class="nav-item right" v-if="logged_in">
                            <a class="nav-link" href="#" @click.prevent="logout">Logout</a>
                        </li>

                    </ul>

                </div>
            </nav>
            <div class=" ">
                <!--<transition name="slide">-->
                <router-view/>
                <!--</transition>-->
            </div>
        </header>

        <section class="vh-100" v-if="showSideMenu">
            <div class="row vh-100">
                <div class="col-md-3 col-lg-2">
                    <SideMenu></SideMenu>
                </div>
                <div class="col-md-9 col-lg-10 mt-3  content">
                    <!--<transition name="slide">-->
                    <router-view/>
                    <!--</transition>-->
                </div>
            </div>
        </section>


    </div>
</template>
<script>
    //    import { useRoute } from 'vue-router';

    import {bus} from  '@/main.js';
    //    import {loader} from '@/main.js';
    import SideMenu from '@/views/account/SideMenu.vue';
    export default{
        components: {SideMenu},
        data(){
            return {
                logged_in: false,
                role: 0,
                loader: null,
            }
        },
        methods: {
            meal_types(){

            },
            logout(){
                window.localStorage.removeItem('menu_filter');
                window.localStorage.removeItem('stpaul-auth');
                window.localStorage.removeItem('stpaul-token');
                window.localStorage.removeItem('stpaul-user');
                window.location.reload();
            },
            showLoader(){
                if (this.loader != null) {
                    this.loader.hide();
                }

                this.loader = this.$loading.show({
                    // Optional parameters
                    container: null,
                    canCancel: true,
                    color: '#ff7a5c',
                });
            },
            hideLoader(){
                if (!this.loader) {
                    return;
                }
                this.loader.hide();
                this.loader = null;
            },
//            loadUser(){
//                var header = new HeaderClass();
//
//                let app = this;
//
//                console.log('load user called');
//                this.axios.get('user', header.stpaul()).then((response) => {
////                    console.log(response.data);
//                    app.$store.commit('SET_USER', response);
//                }).catch((error) => {
//                    console.log(error);
//                    if (error.response.status == 401) {
//                        this.$router.push('login');
//                    }
//
//                })//            }
//            }
        },
        computed: {
            showSideMenu(){
                if (this.$route.name == 'index') {
                    return false;
                }
                if (this.$route.name == 'login') {
                    return false;
                }
                return true;
            }

        },
        mounted(){
//
            bus.$on('load', this.showLoader);
            bus.$on('loaded', this.hideLoader);
            this.logged_in = this.$store.getters.authenticated;
            if (!this.logged_in) {
                return;
            }

            console.log(this.$loader);
//
            this.role = this.$store.getters.user == null ? 0 : this.$store.getters.user.role;
//
//            let app = this;
//
//            setTimeout(function () {
////                var open = app.$store.getters.openRoutes;
////                if (open.includes(app.$route.name)) {
////                    return;
////                }
//                app.loadUser();
//            }, 1000);

        }
    }
</script>
<style>
    body {
        background-color: #f2f7ff !important;
    }

    .nav-link {
        font-weight: bold;
        color: #b55b24 !important;
    }

    .content {
        font-weight: normal;
        color: black;
        font-family: 'arial';
    }

    .card {
        border: none !important;
        border-radius: 15px;
    }

    .card-header {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }

    .card-header:first-child {
        border: none;
        border-bottom: 1px solid #ebebec;
    }

    .main {
        /*position: absolute;*/
        /*width: 100%;*/
        /*top: 0;*/
        position: absolute;
        width: 98%;
        top: 0;
        margin-right: 2%;

    }

    .slide-enter-active, .slide-leave-active {
        transition: 100ms;

    }

    .slide-enter-to {
        position: relative;
        opacity: 1;
        right: 0;
    }

    .sidebar {
        min-height: calc(100vh);
        height: 100%;
    }

    .slide-leave-active,
    .slide-enter-active {
        transition: .5s;
    }

    .slide-enter {

        transform: translate(100%, 0);
        opacity: 0;
    }

    .slide-leave-to {
        transform: translate(-100%, 0);
        opacity: 0;
    }

    /*.show-leave-active {*/
    /*transform: translateX(50%);*/
    /*}*/

    /*.show-enter,*/
    /*.show-leave-to {*/
    /*transform: translateX(100%);*/
    /*}*/
</style>