<template>
    <transition name="fade">
        <div class="alert alert-success" v-if="message.length">
            <strong>Success!</strong> {{message}}
        </div>
    </transition>
</template>

<script>
    export default{
        props: ['message']
    }
</script>

<style>

    .fade-enter-to {
        opacity: 1;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
</style>