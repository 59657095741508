/**
 * Created by Asad on 6/17/2022.
 */


// import axios from 'axios';
// import router from '../router';

import Vue from 'vue';
import VueX from 'vuex';

Vue.use(VueX);
export default new VueX.Store({
    state: {
        authenticated: false,
        user: {},
        tokens: [],
        meal_types: [],
        openRoutes: ['index', 'about', 'login', 'permission'],
        driverRoutes: ['dashboard', 'delivery_slips', 'delivery_slip_detail', 'drivers.signature'],
        sponsor: null,
        sites: [],
        year: 2021,
        // vendorRoutes:['']
    },
    getters: {

        token(){

            return window.localStorage.getItem('stpaul-token');
        },

        tokens(state){
            return state.tokens;
        },
        mealTypes(state){
            return state.meal_types;
        },

        authenticated(){
            if (window.localStorage.getItem('stpaul-auth') == 'true') {
                return true;
            }
            return false;

            // return state.authenticated;
        },
        user(){
            return JSON.parse(window.localStorage.getItem('stpaul-user'));
            // return state.user;
        }, openRoutes(state){
            return state.openRoutes;
        },
        driverRoutes(state){
            return state.driverRoutes;
        },
        sponsor(){
            if (window.localStorage.getItem('stpaul-sponsor') == null) {
                return 0;
            }
            return JSON.parse(window.localStorage.getItem('stpaul-sponsor'));
        },
        year()
        {
            if (window.localStorage.getItem('stpaul-year') == null) {
                return 2021;
            }
            return window.localStorage.getItem('stpaul-year');
        },
        sites(){
            return window.localStorage.getItem('stpaul-sites');
        }
    },
    mutations: {
        SET_AUTHENTICATED (state, value) {
            state.authenticated = value;
            window.localStorage.setItem('stpaul-auth', state.authenticated);
        },
        SET_TOKEN(state, value){
            window.localStorage.setItem('stpaul-token', value);
        },
        SET_USER (state, value) {
            state.user = value;
            window.localStorage.setItem('stpaul-user', JSON.stringify(value));
        },
        SET_TOKENS(state, value){
            state.tokens = value;
        },
        SET_MEAL_TYPES(state, value){
            state.meal_types = value;
        },
        SET_SPONSOR(state, value){
            // state.sponsor = value;
            if (value == null || value == 0 || value == '') {
                return;
            }

            window.localStorage.setItem('stpaul-sponsor', JSON.stringify(value));
        },
        SET_YEAR(state, value){
            window.localStorage.setItem('stpaul-year', value);
        },
        SET_SITES(state, value){
            window.localStorage.setItem('stpaul-sites', value);
        }

    },
    actions: {
        login({commit}, token){

            console.log(commit);
            commit('SET_AUTHENTICATED', true);
            commit('SET_TOKEN', token);
            console.log(token + 'in action');

        },
        logout({commit}){
            commit('SET_TOKEN', '');
            commit('SET_AUTHENTICATED', false)
        },

        setTokens({commit}, tokens){
            commit('SET_TOKENS', tokens);
        },
        setMealTypes({commit}, meal_types){
            commit('SET_MEAL_TYPES', meal_types);
        },
        setSponsor({commit}, sponsor){
            commit('SET_SPONSOR', sponsor);
        },
        setYear({commit}, year){
            commit('SET_YEAR', year);
        },
        setSites({commit}, sites){
            commit('SET_SITES', sites);
        }

    }


})